import { useState } from "react";
import { Col, Row } from "reactstrap";
import API from "../api";
import { ICompanyData, ISearchCompaniesProps, LoadState } from "../constants";
import LoadingSpinner from "./LoadingSpinner";
import SearchBtn from "./SearchBtn";
import "./SearchCompanies.css";
import { ListBox, MaterialIcon } from "./UtilComponents";

interface IState {
    companies: ICompanyData[];
    loadingView: LoadingView;
    viewTable: boolean;
    viewState: LoadState;
}

export enum LoadingView {
    Waiting,
    Searching,
}

const SearchCompanies = (props: ISearchCompaniesProps) => {
    const { passDataToAddnewCompany } = props;

    const [state, setLocalState] = useState<IState>({
        companies: [],
        loadingView: LoadingView.Searching,
        viewState: LoadState.Ready,
        viewTable: false,
    });

    const setState = (newState: Partial<IState>, callback?: () => void) => {
        setLocalState({ ...state, ...newState });
        if (callback) {
            callback();
        }
    };

    const searching = (searchValue: string, searchStatus: boolean) => {
        if (searchStatus) {
            setState({
                loadingView: LoadingView.Waiting,
            });
            API.getCompanies(searchValue, (response) => {
                if (response.state === "success") {
                    setState({
                        companies: response.data as ICompanyData[],
                        loadingView: LoadingView.Searching,
                        viewTable: true,
                    });
                }
            });
        }
    };

    const renderSearchData = (value: ICompanyData, index?: number) => {
        return (
            <tr key={index}>
                <td>{value.Name}</td>
                <td>
                    {value.DefaultAddress !== null && value.DefaultAddress.City !== null
                        ? value.DefaultAddress.City
                        : " "}
                </td>
                <td>
                    <a
                        onClick={() => {
                            passDataToAddnewCompany(value);
                        }}
                    >
                        <MaterialIcon className="size24 md-dark">work</MaterialIcon>
                    </a>
                </td>
            </tr>
        );
    };

    if (state.viewState === LoadState.Loading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <h3 className="my-3">Firma suchen:</h3>
            <Row>
                <Col md={4} />
                <Col md={5}>
                    <SearchBtn searchValue={searching} />
                </Col>
                <Col md={3} />
            </Row>
            {state.viewTable && state.companies && state.loadingView === 1 ? (
                <div style={{ width: "100%", marginTop: "24px" }}>
                    <ListBox<ICompanyData>
                        columnHeaders={["Name", "Adresse", "Annehmen"]}
                        renderOneLine={renderSearchData}
                        listData={state.companies}
                    />
                </div>
            ) : state.loadingView === 0 ? (
                <LoadingSpinner />
            ) : null}
        </>
    );
};

export default SearchCompanies;
