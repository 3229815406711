import * as React from "react";
import { Input, Table } from "reactstrap";
import {
    IClient,
    ICompanyData,
    IEmptyProps,
    IListBoxProps,
    IPositions,
    IQuotations,
    ISelectInputProps,
    ITreoServerGuest,
} from "../constants";

const ListTable = (props: IEmptyProps) => {
    return (
        <Table striped={true} bordered={true} hover={true}>
            {props.children}
        </Table>
    );
};

const ListBox = <T extends ITreoServerGuest | IQuotations | IClient | IPositions | ICompanyData>(
    props: IListBoxProps<T>
) => {
    const renderColumnHeader = (value: string) => {
        return <th key={value}>{value}</th>;
    };

    const noResultsMessage = "Es konnten keine Einträge gefunden werden.";

    const headerClasses: string[] = [];

    if (props.headerStyle) {
        headerClasses.push(props.headerStyle);
    }

    if (props.listData.length < 1) {
        return (
            <ListTable>
                <thead>
                    <tr>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{noResultsMessage}</td>
                    </tr>
                </tbody>
            </ListTable>
        );
    }

    return (
        <ListTable>
            <thead className={headerClasses.join(" ")}>
                <tr>{props.columnHeaders.map(renderColumnHeader)}</tr>
            </thead>
            <tbody>{props.listData.map(props.renderOneLine)}</tbody>
        </ListTable>
    );
};

const SelectInput = (props: ISelectInputProps) => {
    const classes: string[] = [];
    if (props.className) {
        classes.push(props.className);
    }
    return (
        <>
            <>{props.children}</>
            <Input
                id={props.id}
                className={classes.join(" ")}
                name="selectVall"
                type="select"
                required={true}
                onChange={props.onChangeOption}
            >
                {props.selectArrFromParents !== undefined &&
                    props.selectArrFromParents.map((selectVall: string, i: number) => (
                        <option key={i}>{selectVall}</option>
                    ))}
            </Input>
        </>
    );
};

interface IMaterialIconsProps {
    children?: React.ReactNode;
    className?: string;
}

const MaterialIcon = (props: IMaterialIconsProps) => {
    const defaultClasses = "material-icons";
    const classes = [defaultClasses];
    if (props.className) {
        classes.push(props.className);
    }

    return <i className={classes.join(" ")}>{props.children}</i>;
};

export { SelectInput, ListBox, MaterialIcon };
