import React, { useState } from "react";
import { Button, Col, Container, Form, Input, Row } from "reactstrap";
import API from "../api";
import {
    getCompanySectors,
    getCompanyTypes,
    getCountries,
    IAddNewCompanyProps,
    ICompany,
    ICompanyData,
    LoadState,
} from "../constants";
import "./AddNewCompany.css";
import LoadingSpinner from "./LoadingSpinner";
import SearchCompanies from "./SearchCompanies";
import { SelectInput } from "./UtilComponents";

interface IState {
    branche: string;
    brancheList: string[];
    cName: string;
    cType: string;
    cTypesList: string[];
    company: ICompany;
    companyId: number;
    errorView: boolean;
    houseNr: string;
    country: string;
    countryList: string[];
    missingFields: string[];
    city: string;
    plz: string;
    street: string;
    viewState: LoadState;
}

const AddNewCompany = (props: IAddNewCompanyProps) => {
    const [state, setLocalState] = useState<IState>({
        branche: "",
        brancheList: getCompanySectors().sort(),
        cName: "",
        cType: "",
        cTypesList: getCompanyTypes().sort(),
        city: "",
        company: {
            CompanyTypes: [
                {
                    Name: "",
                },
            ],
            DefaultAddress: {
                City: "",
                Country: "",
                HouseNumber: "",
                Street: "",
                ZipCode: "",
            },
            IndustrialSector: "",
            Name: "",
        },
        companyId: 0,
        country: "",
        countryList: getCountries(),
        errorView: false,
        houseNr: "",
        missingFields: [],
        plz: "",
        street: "",
        viewState: LoadState.Ready,
    });

    const setState = (newState: Partial<IState>, callback?: () => void) => {
        setLocalState({ ...state, ...newState });
        if (callback) {
            callback();
        }
    };

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const missingArrayTemp = state.missingFields;
        // tslint:disable-next-line: no-any
        const newState: { [key in keyof IState]: any } = {
            ...state,
        };
        newState[e.target.id as keyof IState] = e.target.value;
        newState.missingFields = missingArrayTemp.filter((entry) => entry !== e.target.id);
        setState(newState);
    };

    const addCompany = () => {
        const requiredFields = ["cName", "street", "plz", "country", "houseNr", "city"];
        const missingFieldsTemp: string[] = [];
        requiredFields.map((field) => {
            if (state[field as keyof IState] === "" || state[field as keyof IState] === 0) {
                missingFieldsTemp.push(field);
            }
        });
        if (missingFieldsTemp.length > 0) {
            setState({ errorView: true, missingFields: missingFieldsTemp });
        } else {
            const company: ICompany = {
                CompanyTypes: [
                    {
                        Name: state.cType,
                    },
                ],
                DefaultAddress: {
                    City: state.city,
                    Country: state.country.substr(0, 2),
                    HouseNumber: state.houseNr,
                    Street: state.street,
                    ZipCode: state.plz,
                },
                IndustrialSector: state.branche,
                Name: state.cName,
            };
            setState({
                company,
            });
            API.createCompany(company, (response) => {
                if (response.state === "success") {
                    setState({
                        branche: "",
                        cName: "",
                        cType: "",
                        city: "",
                        companyId: (response.data as ICompanyData).Id,
                        country: "",
                        errorView: false,
                        houseNr: "",
                        plz: "",
                        street: "",
                    });
                    const CompanyData: ICompanyData = {
                        CompanyTypes: [
                            {
                                Name: company.CompanyTypes[0].Name,
                            },
                        ],
                        DefaultAddress: {
                            City: company.DefaultAddress.City,
                            Country: company.DefaultAddress.Country,
                            HouseNumber: company.DefaultAddress.HouseNumber,
                            Street: company.DefaultAddress.Street,
                            ZipCode: company.DefaultAddress.ZipCode,
                        },
                        Id: (response.data as ICompanyData).Id,
                        IndustrialSector: company.IndustrialSector,
                        Name: company.Name,
                    };
                    props.passDataToNewView(CompanyData);
                }
            });
        }
    };

    const takeDataFromSearchView = (CompanyData: ICompanyData) => {
        if (
            CompanyData.DefaultAddress !== undefined &&
            CompanyData.DefaultAddress.Country !== undefined &&
            CompanyData.Id !== 0 &&
            CompanyData.Name !== undefined
        ) {
            props.passDataToNewView(CompanyData);
        }
    };

    const isMissing = (field: string) => {
        return state.missingFields.indexOf(field) !== -1;
    };
    const errorMsg = (
        <legend className="my-3" style={{ color: "red" }}>
            Bitte füllen Sie die roten Felder aus
        </legend>
    );

    if (state.viewState === LoadState.Loading) {
        return <LoadingSpinner />;
    }
    return (
        <Container>
            <SearchCompanies passDataToAddnewCompany={takeDataFromSearchView} className="mx-3" />
            <br />
            <legend>Neue Firma anlegen:</legend>
            <Form method="post" className="form" role="form">
                <Row>
                    <Col md={6}>
                        <Input
                            id="cName"
                            className={isMissing("cName") ? "error" : "form-control"}
                            name="cName"
                            placeholder="Unternehmensname"
                            type="text"
                            required={true}
                            onChange={inputHandler}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            id="street"
                            className={isMissing("street") ? "error" : "form-control"}
                            name="street"
                            placeholder="Straße"
                            type="text"
                            required={true}
                            onChange={inputHandler}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Input
                            id="houseNr"
                            className={isMissing("houseNr") ? "error" : "form-control"}
                            name="houseNr"
                            placeholder="Haus Nummer"
                            type="text"
                            required={true}
                            onChange={inputHandler}
                        />
                    </Col>
                    <Col md={3}>
                        <Input
                            id="plz"
                            className={isMissing("plz") ? "error" : "form-control"}
                            name="plz"
                            placeholder="PLZ"
                            type="text"
                            required={true}
                            onChange={inputHandler}
                        />
                    </Col>
                    <Col md={3}>
                        <Input
                            id="city"
                            className={isMissing("city") ? "error" : "form-control"}
                            name="city"
                            placeholder="Ort"
                            type="text"
                            required={true}
                            onChange={inputHandler}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <SelectInput id="cType" selectArrFromParents={state.cTypesList} onChangeOption={inputHandler} />
                    </Col>
                    <Col md={3}>
                        <SelectInput
                            id="branche"
                            selectArrFromParents={state.brancheList}
                            onChangeOption={inputHandler}
                        />
                    </Col>
                    <Col md={3}>
                        <SelectInput
                            id="country"
                            className={isMissing("country") ? "error" : "form-control"}
                            selectArrFromParents={state.countryList}
                            onChangeOption={inputHandler}
                        />
                    </Col>
                </Row>
                {state.errorView && errorMsg}
                <br />
                <Button size="lg" color="primary" block={true} type="button" onClick={addCompany}>
                    Anlegen
                </Button>
            </Form>
        </Container>
    );
};

export default AddNewCompany;
