import moment from "moment";
import * as constants from "./constants";

export default class API {
    public static getAccessData(): constants.IAccessData {
        const accessDataStr = localStorage.getItem(constants.TOKEN_STORAGE_KEY);
        let accessData: constants.IAccessData;
        if (!accessDataStr) {
            accessData = {
                accessToken: "",
                refreshToken: "",
                userId: 0,
                userName: "",
            };
        } else {
            accessData = JSON.parse(accessDataStr);
        }
        return accessData;
    }

    public static setAccessData(data: constants.IAccessData) {
        localStorage.setItem(constants.TOKEN_STORAGE_KEY, JSON.stringify(data));
    }

    public static deleteLocalStorage() {
        localStorage.removeItem(constants.TOKEN_STORAGE_KEY);
        localStorage.removeItem(constants.CUSTOM_FIELDS_STORAGE_KEY);
    }

    public static sendHQRequest = (
        meth: string,
        url: string,
        body: string | null,
        callback: (response: constants.ICallbackResponse) => void
    ) => {
        const accessData = API.getAccessData();
        const myInit: RequestInit = {
            credentials: "omit",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + accessData.accessToken,
                "Content-Type": "application/json",
            },
            method: meth,
        };

        if (body != null) {
            myInit.body = body;
        }
        url = constants.HQ_URL + url;
        const myRequest = new Request(url, myInit);
        fetch(myRequest)
            .then((response) => {
                if (response.status === 400) {
                    callback({
                        code: response.status,
                        message: "Bad Request",
                        state: "error",
                    });
                    return;
                }
                if (response.status === 204) {
                    callback({
                        code: response.status,
                        message: "No Content",
                        state: "success",
                    });
                    return;
                }
                response
                    .json()
                    .then((json) => {
                        if (response.ok) {
                            if (json.value != null) {
                                json = json.value;
                            }
                            callback({ state: "success", data: json, code: response.status });
                        } else {
                            if (json.error.innererror != null) {
                                // console.log(json.error.innererror.message);
                            }
                            callback({
                                code: response.status,
                                message: json.error.message,
                                state: "error",
                            });
                        }
                    })
                    .catch((err) => {
                        callback({ state: "error", message: err, code: 500 });
                    });
            })
            .catch((err) => {
                callback({ state: "error", message: err, code: 500 });
            });
    };

    public static sendTreoRequest = (
        meth: string,
        url: string,
        body: string | null,
        callback: (response: constants.ICallbackResponse) => void
    ) => {
        const myInit: RequestInit = {
            method: meth,
        };
        if (body != null) {
            myInit.body = body;
        }
        url = constants.TREO_SERVER_API_URL + url;
        const myRequest = new Request(url, myInit);
        fetch(myRequest)
            .then((response) => {
                if (response.status === 204) {
                    callback({
                        code: response.status,
                        message: "No Content",
                        state: "success",
                    });
                    return;
                }
                if (response.status === 400) {
                    callback({
                        code: response.status,
                        message: "Bad Request",
                        state: "error",
                    });
                    return;
                }
                response
                    .json()
                    .then((json) => {
                        if (response.ok) {
                            if (json.value != null) {
                                json = json.value;
                            }
                            callback({ state: "success", data: json, code: response.status });
                        } else {
                            if (json.error.innererror != null) {
                                // console.log(json.error.innererror.message);
                            }
                            callback({
                                code: response.status,
                                message: json.error.message,
                                state: "error",
                            });
                        }
                    })
                    .catch((err) => {
                        callback({ state: "error", message: err, code: 500 });
                    });
            })
            .catch((err) => {
                callback({ state: "error", message: err, code: 500 });
            });
    };

    public static getAccessToken = (ac: string, callback: (response: constants.ICallbackResponse) => void) => {
        const str =
            "redirect_uri=" +
            encodeURIComponent(constants.TREO_SERVER_URL + "check.html") +
            "&grant_type=authorization_code&code=" +
            ac;
        const myInit: RequestInit = {
            body: str,
            credentials: "omit",
            headers: {
                Authorization: "Basic " + Base64.encode(constants.APP_ID_AND_APP_SECRET),
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
        };
        const myRequest = new Request(constants.TOKEN_HQ_URL, myInit);
        fetch(myRequest).then((response) => {
            response.json().then((json) => {
                const accessData: constants.IAccessData = {
                    accessToken: json.access_token,
                    refreshToken: json.refresh_token,
                    userId: json.user_id,
                    userName: json.user_name,
                };
                API.setAccessData(accessData);
                callback({ status: "success", data: accessData });
            });
        });
    };

    public static calculateFontSize(str: string, offset?: number): string {
        const strLength: number = str.length;
        let fontSize: number = 0;
        let fontValue: string = "";
        if (strLength <= 12) {
            fontSize = 32;
        } else if (strLength > 12 && strLength <= 16) {
            fontSize = 30;
        } else if (strLength > 16 && strLength <= 20) {
            fontSize = 28;
        } else if (strLength > 20 && strLength <= 24) {
            fontSize = 26;
        } else if (strLength > 24 && strLength <= 28) {
            fontSize = 24;
        } else if (strLength > 28 && strLength <= 32) {
            fontSize = 22;
        } else if (strLength > 32) {
            fontSize = 20;
        }

        if (offset) {
            fontSize = fontSize + offset;
        }
        fontValue = fontSize + "px";
        return fontValue;
    }

    public static openLabelPrint = (printData: constants.IPrintData) => {
        const myWindow = window.open("Etikettendruck");
        const img = "/assets/treo-logo-vert.png";
        const name: string = printData.name !== undefined ? printData.name : "";
        const company: string = printData.company !== undefined ? printData.company : "";
        const host: string = printData.host !== undefined ? printData.host : "";
        const timeIn: string = printData.timeIn !== undefined ? printData.timeIn : "";
        if (myWindow !== null) {
            myWindow.document.write(
                `<!DOCTYPE html><html><head><meta charset="utf-8"><style type="text/css" media="print">@page{size: 101mm 54mm; margin: 10mm;} body{box-sizing: border-box;` +
                    `width: 101mm;height: 54mm;page-break-inside: avoid;display: block; margin: 0 auto; font-family: Arial} div{list-style-type: none; float: right;}` +
                    `img{float: lef;max-height: 180px;box-sizing: border-box;padding: 10px;border: 10px;display: inline; margin: 0 auto}.btn{display:none;}li{text-align: right;}` +
                    `span{ font-size: 20px; margin: 10px auto; padding: 5px; } #visitorName{font-size:${API.calculateFontSize(
                        name,
                        -2
                    )};}` +
                    `#companyName{font-size:${API.calculateFontSize(
                        company,
                        -6
                    )}; text-align: right;margin: 5px auto;} #hostName{font-size:${API.calculateFontSize(host, -6)};` +
                    `text-align: right;margin: 6px auto;}</style><style type="text/css"> #visitorName{font-size:${API.calculateFontSize(
                        name,
                        -2
                    )}; text-align: right;margin: 3px auto;} body{box-sizing: border-box;width: 101mm;height: 54mm;page-break-inside: avoid;` +
                    `display: block; margin: 0 auto; font-family: Arial} div{ display: inline;float: right;}img{margin: 0px auto; float: lef;max-height: 180px;box-sizing: border-box;` +
                    `padding: 10px;border: 10px;display: inline;}li{text-align: right;} span{ font-size: 20px; margin: 5px auto; padding: 5px; }` +
                    `#companyName{font-size:${API.calculateFontSize(
                        company,
                        -6
                    )}; text-align: right;margin: 5px auto;} #badgePreview{display: flex;flex-direction: row; width: 100%}` +
                    `#hostName{font-size:${API.calculateFontSize(
                        host,
                        -6
                    )}; text-align: right;margin: 5px auto;}button.btn{ float: none;` +
                    `padding: 10px;margin: 5px;}</style></head><body><div id="badgePreview"><img src=${img} class="img-responsive" alt="treo-logo" /><ul style="list-style-type: none">` +
                    `<li style="margin 0px 0px 20px 0px"><span style="margin: 10px auto">Visitor</span></li><li><b id="visitorName">${name}</b></li><li>` +
                    `<p id="companyName">${company}</p></li><li><p id="hostName"><span style="text-align: left">visiting</span> ${host}</p></li> <li><span>${timeIn}</span>` +
                    `</li></ul></div><button class="btn" type="button" style="float: right" onClick={window.print()} >Drucken</button>` +
                    `<button class="btn" style="float: right" type="button" onClick={window.close()} >Schließen</button> </body></html>`
            );
        }
    };

    public static getCompanies(query: string, callback: (response: constants.ICallbackResponse) => void) {
        const url: string =
            "Companies?$expand=Addresses,DefaultAddress&$filter=contains(Name,'" + query.trim() + "')&$orderby=Name";
        this.sendHQRequest("GET", url, null, callback);
    }

    public static saveCustomFieldLocally(callback?: () => void) {
        const saveDate = moment().toString();
        const CustomFieldsObject: constants.ICustomFieldsObject[] = [{ Name: "saveDate", Value: saveDate }];
        const url: string = "Articles?$select=CustomFields&$expand=CustomFields";
        this.sendHQRequest("GET", url, null, (response) => {
            if (response && response.data !== undefined) {
                (response.data as constants.IHQArticle[]).map((response1: constants.IHQArticle) => {
                    if (response1.CustomFields !== undefined) {
                        response1.CustomFields.map((CustomField: constants.IHQCustomField) => {
                            if (CustomField.Name !== "" && CustomField.Value !== "") {
                                CustomFieldsObject.push({
                                    Name: CustomField.Name,
                                    Value: CustomField.Value,
                                });
                            }
                        });
                        localStorage.setItem(constants.CUSTOM_FIELDS_STORAGE_KEY, JSON.stringify(CustomFieldsObject));
                    }
                });
                if (callback) {
                    callback();
                }
            }
        });
    }

    public static getCustomFieldsFromLocal(query: string): string[] {
        const CustomFieldsObject = localStorage.getItem(constants.CUSTOM_FIELDS_STORAGE_KEY);
        const responseArr: string[] = [];
        if (CustomFieldsObject !== null) {
            JSON.parse(CustomFieldsObject).map((obj: constants.IHQCustomField) => {
                if (
                    obj.Name.includes(query) &&
                    obj.Value !== "" &&
                    !obj.Value.includes(";") &&
                    responseArr.indexOf(obj.Value) === -1
                ) {
                    responseArr.push(obj.Value);
                }
            });
        }
        return responseArr;
    }

    public static getCustomerForCompanyId(query: number, callback: (response: constants.ICallbackResponse) => void) {
        const url: string = "ContactPersons?$filter=CompanyId eq " + query;
        this.sendHQRequest("GET", url, null, callback);
    }

    public static searchCompanyName(searchValue: string, callback: (response: constants.ICallbackResponse) => void) {
        const dataFromName: constants.IQuotations[] = [];
        API.getAllQuotations((response) => {
            if (response.state === "success") {
                const dataAllFromName: constants.IQuotations[] = response.data as constants.IQuotations[];
                dataAllFromName.map((entry: constants.IQuotations) => {
                    if (
                        entry !== undefined &&
                        entry.Company.Name.toLowerCase().includes(searchValue.trim().toLowerCase())
                    ) {
                        dataFromName.push(entry);
                    }
                });
                callback({ state: "success", data: dataFromName });
            } else {
                callback({ state: "err" });
            }
        });
    }

    public static createCompany(data: constants.ICompany, callback: (response: constants.ICallbackResponse) => void) {
        const url: string = "Companies";
        API.sendHQRequest("POST", url, JSON.stringify(data), callback);
    }

    public static getGuests = (
        inBuilding: boolean,
        searchString: string,
        callback: (response: constants.ICallbackResponse) => void
    ) => {
        const url = "guests/list";
        const data = {
            inBuilding,
            listParameters: { pageNumber: 1, pageSize: 50, searchString },
        };
        API.sendTreoRequest("POST", url, JSON.stringify(data), callback);
    };

    public static createContactPerson(
        person: constants.ICreateClient,
        callback: (response: constants.ICallbackResponse) => void
    ) {
        const url: string = "ContactPersons";
        API.sendHQRequest("POST", url, JSON.stringify(person), callback);
    }

    public static signoutVisitor(
        id: string,
        departureDate: Date,
        callback: (response: constants.ICallbackResponse) => void
    ) {
        const accessData = API.getAccessData();
        const url = "guests/remove";
        const data = { id, token: accessData.accessToken, departureDate };
        API.sendTreoRequest("POST", url, JSON.stringify(data), callback);
    }

    public static getAllQuotations(callback: (response: constants.ICallbackResponse) => void) {
        const url: string = "Quotations?$expand=Company,Positions,Lead&$orderby=QuotationDate desc";
        this.sendHQRequest("GET", url, null, callback);
    }
}

const Base64 = {
    _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
    _utf8_decode: (e: string) => {
        let t = "";
        let n = 0;
        let r, c1, c2;
        r = c1 = c2 = 0;
        while (n < e.length) {
            r = e.charCodeAt(n);
            if (r < 128) {
                t += String.fromCharCode(r);
                n++;
            } else if (r > 191 && r < 224) {
                c2 = e.charCodeAt(n + 1);
                t += String.fromCharCode(((r & 31) << 6) | (c2 & 63));
                n += 2;
            } else {
                c2 = e.charCodeAt(n + 1);
                c1 = e.charCodeAt(n + 2);
                t += String.fromCharCode(((r & 15) << 12) | ((c2 & 63) << 6) | (c1 & 63));
                n += 3;
            }
        }
        return t;
    },
    _utf8_encode: (e: string) => {
        e = e.replace(/\r\n/g, "\n");
        let t = "";
        let r;
        for (let n = 0; n < e.length; n++) {
            r = e.charCodeAt(n);
            if (r < 128) {
                t += String.fromCharCode(r);
            } else if (r > 127 && r < 2048) {
                t += String.fromCharCode((r >> 6) | 192);
                t += String.fromCharCode((r & 63) | 128);
            } else {
                t += String.fromCharCode((r >> 12) | 224);
                t += String.fromCharCode(((r >> 6) & 63) | 128);
                t += String.fromCharCode((r & 63) | 128);
            }
        }
        return t;
    },
    decode: (e: string) => {
        let t = "";
        let n, r, i;
        let s, o, u, a;
        let f = 0;
        e = e.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        while (f < e.length) {
            s = Base64._keyStr.indexOf(e.charAt(f++));
            o = Base64._keyStr.indexOf(e.charAt(f++));
            u = Base64._keyStr.indexOf(e.charAt(f++));
            a = Base64._keyStr.indexOf(e.charAt(f++));
            n = (s << 2) | (o >> 4);
            r = ((o & 15) << 4) | (u >> 2);
            i = ((u & 3) << 6) | a;
            t = t + String.fromCharCode(n);
            if (u !== 64) {
                t = t + String.fromCharCode(r);
            }
            if (a !== 64) {
                t = t + String.fromCharCode(i);
            }
        }
        t = Base64._utf8_decode(t);
        return t;
    },
    encode: (e: string) => {
        let t = "";
        let n, r, i, s, o, u, a;
        let f = 0;
        e = Base64._utf8_encode(e);
        while (f < e.length) {
            n = e.charCodeAt(f++);
            r = e.charCodeAt(f++);
            i = e.charCodeAt(f++);
            s = n >> 2;
            o = ((n & 3) << 4) | (r >> 4);
            u = ((r & 15) << 2) | (i >> 6);
            a = i & 63;
            if (isNaN(r)) {
                u = a = 64;
            } else if (isNaN(i)) {
                a = 64;
            }
            t =
                t +
                Base64._keyStr.charAt(s) +
                Base64._keyStr.charAt(o) +
                Base64._keyStr.charAt(u) +
                Base64._keyStr.charAt(a);
        }
        return t;
    },
};
