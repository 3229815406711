import { useState } from "react";
import { Container } from "reactstrap";
import AddNewComp from "../components/AddNewCompany";
import AddVisitor from "../components/AddVisitor";
import LoadingSpinner from "../components/LoadingSpinner";
import NavBar from "../components/NavBarComponent";
import { ICompanyData, LoadState } from "../constants";

interface IState {
    companyData: ICompanyData;
    viewState: LoadState;
}

const NewView = () => {
    const [state, setLocalState] = useState<IState>({
        companyData: {
            CompanyTypes: [
                {
                    Name: "",
                },
            ],
            DefaultAddress: {
                City: "",
                Country: "",
                HouseNumber: "",
                Street: "",
                ZipCode: "",
            },
            Id: 0,
            IndustrialSector: "",
            Name: "0",
        },
        viewState: LoadState.Ready,
    });

    const setState = (newState: Partial<IState>, callback?: () => void) => {
        setLocalState({ ...state, ...newState });
        if (callback) {
            callback();
        }
    };

    const passData = (Data: ICompanyData) => {
        if (
            Data.DefaultAddress !== null &&
            Data.DefaultAddress.Country !== undefined &&
            Data.Id !== 0 &&
            Data.Name !== undefined
        ) {
            setState({
                companyData: Data,
            });
        }
    };
    const { companyData } = state;
    if (state.viewState === LoadState.Loading) {
        return <LoadingSpinner />;
    }

    return (
        <Container>
            <NavBar />
            <>
                {companyData.Id !== 0 ? (
                    <AddVisitor takeDataFromAnotherChild={companyData} />
                ) : (
                    <AddNewComp passDataToNewView={passData} />
                )}
            </>
        </Container>
    );
};

export default NewView;
