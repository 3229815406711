import * as React from "react";
import API from "../api";
import LoadingSpinner from "../components/LoadingSpinner";
import { IAccessData, IEmptyProps, LoadState, TREO_SERVER_URL } from "../constants";

interface IState {
    viewState: LoadState;
}

export default class CheckLogInView extends React.PureComponent<IEmptyProps, IState> {
    constructor(props: IEmptyProps) {
        super(props);
        this.state = {
            viewState: LoadState.Loading,
        };
    }

    public componentDidMount() {
        const url = new URL(window.location.href);
        const ac = url.searchParams.get("code");
        if (ac !== null) {
            API.getAccessToken(ac, (response) => {
                if ((response.data as IAccessData).accessToken) {
                    window.location.href = TREO_SERVER_URL + "#/listOfGuest";
                }
            });
        }
    }

    public render() {
        if (this.state.viewState === LoadState.Loading) {
            return <LoadingSpinner />;
        } else {
            return null;
        }
    }
}
