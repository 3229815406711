import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { theme } from "theme";
import TestPrintBadge from "views/TestPrintBadge";
import PrivateRoute from "./components/PrivateRoute";
import { MaterialIcon } from "./components/UtilComponents";
import { IEmptyProps } from "./constants";
import CheckLogInView from "./views/CheckLogInView";
import GuestsHistory from "./views/GuestHistory";
import ListOfGuest from "./views/ListOfGuest";
import LogInView from "./views/LogInView";
import Logout from "./views/Logout";
import NewView from "./views/NewView";

interface IState {
    isScrolling: number;
}

export default class App extends React.Component<IEmptyProps, IState> {
    constructor(props: IEmptyProps) {
        super(props);
        this.state = {
            isScrolling: 0,
        };
    }

    public componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    public componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    public handleScroll = () => {
        this.setState({
            isScrolling: document.body.scrollTop || document.documentElement.scrollTop,
        });
    };

    public render() {
        return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={theme}>
                    <HashRouter>
                        <Routes>
                            <Route path="/" element={<LogInView />} />
                            <Route path="/test" element={<TestPrintBadge />} />
                            <Route path="/start" element={<CheckLogInView />} />
                            <Route path="/Logout" element={<Logout />} />
                            <Route path="/ListOfGuest" element={<PrivateRoute />}>
                                <Route path="/ListOfGuest" element={<ListOfGuest />} />
                            </Route>
                            <Route path="/GuestsHistory" element={<PrivateRoute />}>
                                <Route path="/GuestsHistory" element={<GuestsHistory />} />
                            </Route>
                            <Route path="/NewView" element={<PrivateRoute />}>
                                <Route path="/NewView" element={<NewView />} />
                            </Route>
                        </Routes>
                    </HashRouter>
                    <a
                        id="myTopBtn"
                        style={{ visibility: this.state.isScrolling > 20 ? "visible" : "hidden" }}
                        onClick={() => {
                            document.body.scrollTop = 0;
                            document.documentElement.scrollTop = 0;
                        }}
                        title="Nach oben"
                    >
                        <MaterialIcon className="size28 md-dark">present_to_all</MaterialIcon>
                    </a>
                </ThemeProvider>
            </LocalizationProvider>
        );
    }
}
