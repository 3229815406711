import * as React from "react";
import API from "../api";
import LoadingSpinner from "../components/LoadingSpinner";
import { IEmptyProps, LoadState, TREO_SERVER_URL } from "../constants";

interface IState {
    viewState: LoadState;
}

export default class Logout extends React.PureComponent<IEmptyProps, IState> {
    constructor(props: IEmptyProps) {
        super(props);
        this.state = {
            viewState: LoadState.Loading,
        };
    }

    public componentDidMount() {
        API.deleteLocalStorage();
        window.location.href = TREO_SERVER_URL;
    }

    public render() {
        if (this.state.viewState === LoadState.Loading) {
            return <LoadingSpinner />;
        } else {
            return null;
        }
    }
}
