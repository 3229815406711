import * as React from "react";
import { Outlet } from "react-router-dom";
import API from "../api";
import { TREO_SERVER_URL } from "../constants";

const PrivateRoute = (): JSX.Element | null => {
    const accessData = API.getAccessData();
    React.useEffect(() => {
        if (accessData.accessToken.length < 1) {
            window.location.href = TREO_SERVER_URL;
        }
    }, [window.location.href]);

    if (accessData.accessToken.length > 0) {
        return <Outlet />;
    } else {
        return null;
    }
};

PrivateRoute.displayName = "PrivateRoute";
export default PrivateRoute;
