import moment from "moment";
import "moment/locale/de";
import * as React from "react";
import { Container } from "reactstrap";
import API from "../api";
import LoadingSpinner from "../components/LoadingSpinner";
import NavBar from "../components/NavBarComponent";
import { ListBox, MaterialIcon } from "../components/UtilComponents";
import { IEmptyProps, ITreoServerGuest, LoadState, TREO_SERVER_API_URL } from "../constants";
import "./GuestHistory.css";

interface IState {
    guests: ITreoServerGuest[];
    viewTable: boolean;
    viewState: LoadState;
}

export default class GuestHistory extends React.PureComponent<IEmptyProps, IState> {
    constructor(props: IEmptyProps) {
        super(props);
        this.state = {
            guests: [],
            viewState: LoadState.Loading,
            viewTable: false,
        };
    }

    public componentDidMount() {
        API.getGuests(false, "", (response) => {
            this.setState({
                viewState: LoadState.Ready,
            });
            if (response.state === "success") {
                this.setState({
                    guests: (response.data as { list: ITreoServerGuest[] }).list,
                    viewTable: true,
                });
            }
        });
    }

    public renderHisGuest = (value: ITreoServerGuest, index?: number) => {
        const href = TREO_SERVER_API_URL + "pdf/download/" + value.id;
        return (
            <tr key={index}>
                <td>{value.name}</td>
                <td>{value.company.name}</td>
                <td>{value.host.name}</td>
                <td>{value.location}</td>
                <td>{moment(value.arrivalDate).format("LLLL")}</td>
                <td>{moment(value.departureDate).format("LLLL")}</td>
                <td>
                    <a href={href}>
                        <MaterialIcon className="size24 md-dark">get_app</MaterialIcon>
                    </a>
                </td>
            </tr>
        );
    };

    public render() {
        if (this.state.viewState === LoadState.Loading) {
            return <LoadingSpinner />;
        } else {
            return (
                <Container fluid={false}>
                    <NavBar />
                    <h3 className="my-3">Besucherhistorie</h3>
                    {this.state.viewTable && (
                        <ListBox<ITreoServerGuest>
                            columnHeaders={["Name", "Firma", "Gastgeber", "Standort", "Ankunft", "Verlassen", "PDF"]}
                            renderOneLine={this.renderHisGuest}
                            listData={this.state.guests}
                        />
                    )}
                </Container>
            );
        }
    }
}
