import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import { ISearchBtnProps } from "../constants";
import { MaterialIcon } from "./UtilComponents";

interface IState {
    search: string;
}

const SearchBtn = (props: ISearchBtnProps) => {
    const [state, setLocalState] = useState<IState>({
        search: "",
    });

    const setState = (newState: Partial<IState>, callback?: () => void) => {
        setLocalState({ ...state, ...newState });
        if (callback) {
            callback();
        }
    };

    const searching = () => {
        console.log("TRIGGERED");
        if (state.search) {
            props.searchValue(state.search, true);
        }
    };

    return (
        <>
            <div style={{ display: "flex", columnGap: "12px" }}>
                {props.children}
                <Input
                    id="search"
                    name="search"
                    type="search"
                    placeholder="Suchen"
                    aria-label="Search"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setState({ search: e.target.value });
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            searching();
                        }
                    }}
                    style={{ margin: "0px auto" }}
                />
                <Button outline={true} color="primary" type="button" onClick={searching}>
                    <div style={{ display: "flex", columnGap: "12px" }}>
                        Suchen <MaterialIcon className="size24">search</MaterialIcon>
                    </div>
                </Button>
            </div>
        </>
    );
};

export default SearchBtn;
