import moment from "moment";
import * as React from "react";
import * as constants from "../constants";
import "./LogInView.css";

export interface VisitorCompany {
    id: number;
    name: string;
}

export interface VisitorHost {
    id: number;
    name: string;
    firstName: string;
    lastName: string;
}

export interface Visitor {
    id: string;
    entryID: number;
    salutation: string;
    location: string;
    name: string;
    firstName: string;
    lastName: string;
    company: VisitorCompany;
    host: VisitorHost;
    inBuilding: boolean;
    arrivalDate: string;
    departureDate: string | null;
    pdfID: string;
    signOutError: string;
    signature: string;
}

export interface DataToPrint {
    name: string;
    company: string;
    host: string;
    timeIn: string;
    fontSizes: {
        name: string | number;
        company: string | number;
        timeIn: string | number;
        host: string | number;
    };
}

export const getStringsToPrint = (
    visitor: Visitor
): {
    dataToPrint: {
        company: string;
        fontSizes: {
            company: string | number;
            host: string | number;
            name: string | number;
            timeIn: string | number;
        };
        host: string;
        name: string;
        timeIn: string;
    };
} => {
    const visitorFullName = visitor.firstName + " " + visitor.lastName;
    return {
        dataToPrint: {
            name: visitorFullName.length > 26 ? visitorFullName.substring(0, 22) + "..." : visitorFullName,
            company:
                visitor.company.name.length >= 45
                    ? visitor.company.name.substring(0, 41) + "..."
                    : visitor.company.name,
            host: visitor.host.name,
            timeIn: moment(visitor.arrivalDate).format("YYYY-MM-DD"),
            // timeIn: new Date(visitor.arrivalDate).toISOString(),
            fontSizes: {
                company: 200,
                timeIn: 200,
                host: 200,
                name: 300,
            },
        },
    };
};

export const getBadgeToPrint = (dataToPrint: DataToPrint): string => {
    const image = constants.TREO_SERVER_URL + "assets/treo-logo-vert.png";
    // 101mm = 381.732283465px
    // 54mm = 204.094488189
    const html = `<!DOCTYPE html>
            <html>
                <head>
                    <meta charset="utf-8">
                    <meta name="viewport" content="width=device-width, initial-scale=2.0">
                    <style type="text/css" media="print">
                    @page{size: 860mm 600mm} 
                        html{
                            box-sizing: border-box;
                        }
                        body{
                            box-sizing: border-box;
                            display: block; 
                            font-family: Arial;
                            width: 860mm;
                            height: 590mm;
                        }
                        img{
                            max-height: 100%;
                            margin-left: 5%;
                        }
                        span {
                            margin: 0;
                        }
                        p {
                            margin-block-start: 0;
                            margin-block-end: 0;
                        }
                        .btn{
                            display:none;
                        }
                        #list {
                            display: flex;
                            flex: 1;
                            flex-direction: column;
                            justify-content: center;
                            padding-left: 5%;
                            padding-right: 5%;
                            text-align: right;
                        }
                        #badgePreview{
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            height: 100%;
                        }
                        #visitorName{
                            font-size:${dataToPrint.fontSizes.name}px;
                        }
                        #companyName{
                            font-size:${dataToPrint.fontSizes.company}px;
                            margin: 0 auto;
                        } 
                        #hostName{
                            font-size:${dataToPrint.fontSizes.host}px;
                        }
                    </style>
                </head>
                <body>
                    <div id="badgePreview">
                        <img src=${image} class="img-responsive" alt="treo-logo" />
                        <div id="list">
                            <div>
                                <p style="font-size: ${dataToPrint.fontSizes.timeIn}px">Visitor</p>
                            </div>
                            <div>
                                <b id="visitorName">${dataToPrint.name}</b>
                            </div>
                            <div>
                                <b id="companyName">${dataToPrint.company}</b>
                            </div>
                            <div>
                                <p id="hostName">
                                    <span>visiting</span> ${dataToPrint.host}
                                </p>
                            </div>
                            <div>
                                <span style="font-size: ${dataToPrint.fontSizes.timeIn}px">${dataToPrint.timeIn}</span>
                            </div>
                        </div>
                    </div>
                </body>
            </html>`;
    return html;
};

const executePrint = () => {
    const printData = getStringsToPrint({
        id: "0111",
        entryID: 1,
        salutation: "Herr",
        location: "Deutschland",
        name: "Ingolf Ernst",
        firstName: "Ingolf",
        lastName: "Ernst",
        company: {
            id: 1,
            name: "Qubidu GmbH",
        },
        host: {
            id: 1,
            name: "Ingolf Ernst",
            firstName: "Ingolf",
            lastName: "Ernst",
        },
        inBuilding: false,
        arrivalDate: "2024-03-13T08:00:00.000Z",
        departureDate: null,
        pdfID: "234asd",
        signOutError: "",
        signature: "string",
    }).dataToPrint;
    const printWindow = window.open("", "_blank");
    if (!printWindow) {
        return;
    }
    printWindow.document.write(getBadgeToPrint(printData));
    printWindow.document.close();
    setTimeout(() => {
        printWindow.print();
    }, 100);
};

export default class TestPrintBadge extends React.Component<constants.IEmptyProps> {
    constructor(props: constants.IEmptyProps) {
        super(props);
    }

    public render() {
        return (
            <div className="TestView">
                <button className="btn btn-primary" onClick={executePrint}>
                    Drucken
                </button>
            </div>
        );
    }
}
