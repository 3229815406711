import { useEffect, useState } from "react";
import {
    Collapse,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Navbar,
    NavbarToggler,
} from "reactstrap";
import API from "../api";
import { TREO_SERVER_URL } from "../constants";
import "./NavBar.css";

interface IState {
    client: object[];
    isOpen: boolean;
    isOpenDropdown: boolean;
    userName: string;
}

const NavBarComponent = () => {
    const [state, setLocalState] = useState<IState>({
        client: [],
        isOpen: false,
        isOpenDropdown: false,
        userName: "",
    });

    const setState = (newState: Partial<IState>, callback?: () => void) => {
        setLocalState({ ...state, ...newState });
        if (callback) {
            callback();
        }
    };

    useEffect(() => {
        const accessData = API.getAccessData();
        setState({
            userName: accessData.userName,
        });
    }, []);

    const toggleCollapse = () => {
        setState({
            isOpen: !state.isOpen,
        });
    };

    const toggleDropdown = () => {
        setState({
            isOpenDropdown: !state.isOpenDropdown,
        });
    };

    const thisLink: string = location.href;
    const visLink: string = TREO_SERVER_URL + "#/ListOfGuest";
    const hisLink: string = TREO_SERVER_URL + "#/GuestsHistory";
    const newLink: string = TREO_SERVER_URL + "#/NewView";
    // const offerLink: string = TREO_SERVER_URL + "#/OfferList";
    const Logout = `${TREO_SERVER_URL}#/Logout`;

    return (
        <Navbar color="light" light={true} expand="lg">
            <img src="assets/treo-logo.png" alt="treo-logo" />
            <NavbarToggler onClick={toggleCollapse} />
            <Collapse isOpen={state.isOpen} navbar={true}>
                <Nav navbar={true} className="mr-auto active">
                    <NavItem>
                        <NavLink active={thisLink === visLink ? true : false} href={visLink}>
                            Aktuelle Besucher
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink active={thisLink === hisLink ? true : false} href={hisLink}>
                            Besucherhistorie
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink active={thisLink === newLink ? true : false} href={newLink}>
                            Neu anlegen
                        </NavLink>
                    </NavItem>
                    {/* <NavItem>
                        <NavLink active={thisLink === offerLink ? true : false} href={offerLink}>
                            Angebote
                        </NavLink>
                    </NavItem> */}
                </Nav>
                <Nav navbar={true} style={{ marginLeft: "auto" }}>
                    <Dropdown nav={true} inNavbar={true} isOpen={state.isOpenDropdown} toggle={toggleDropdown}>
                        <DropdownToggle nav={true} caret={true}>
                            <b>{state.userName}</b>
                        </DropdownToggle>
                        <DropdownMenu end>
                            <DropdownItem>
                                <NavItem>
                                    <NavLink href={Logout}>Logout</NavLink>
                                </NavItem>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default NavBarComponent;
