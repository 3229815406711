import * as React from "react";
import { Button, Col, Container, Form, Row } from "reactstrap";
import * as constants from "../constants";
import "./LogInView.css";

export default class LogInView extends React.Component<constants.IEmptyProps> {
    constructor(props: constants.IEmptyProps) {
        super(props);
    }

    public login = () => {
        const startURL = encodeURIComponent(constants.TREO_SERVER_URL + "check.html");
        window.location.href =
            "https://api.hqlabs.de/Account/Authorize?response_type=code&client_id=" +
            constants.APP_ID +
            "&state=log&redirect_uri=" +
            startURL +
            "&scope=read_all%20write_all";
    };

    public render() {
        return (
            <div className="LogInView">
                <Container>
                    <Row>
                        <Col md={3} lg={4} />
                        <Col sm={10} xs={12} md={6} lg={4}>
                            <Form method="post" role="login">
                                <section className="login-form">
                                    <img src="assets/treo-logo.png" className="img-responsive" alt="treo-logo" />
                                    <Button
                                        size="lg"
                                        color="primary"
                                        outline={true}
                                        block={true}
                                        type="button"
                                        onClick={this.login}
                                    >
                                        Sign in
                                    </Button>
                                </section>
                            </Form>
                        </Col>
                        <Col md={3} lg={4} />
                    </Row>
                </Container>
            </div>
        );
    }
}
