import { AccessTime, CalendarMonth } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import "moment/locale/de";
import { useEffect, useState } from "react";
import { Container, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import API from "../api";
import LoadingSpinner from "../components/LoadingSpinner";
import NavBar from "../components/NavBarComponent";
import { ListBox, MaterialIcon } from "../components/UtilComponents";
import { IPrintData, ITreoServerGuest, LoadState } from "../constants";
import "./ListOfGuest.css";
interface IState {
    guests: ITreoServerGuest[];
    printData: IPrintData;
    viewState: LoadState;
    removeId: string;
    removeVisitor: boolean;
    viewTable: boolean;
    visitorDate?: Date;
    visitorTime?: Date;
    dateOpen: boolean;
    timeOpen: boolean;
}

const ListOfGuest = () => {
    const [state, setLocalState] = useState<IState>({
        guests: [],
        printData: {
            company: "",
            host: "",
            name: "",
            timeIn: "",
            title: "",
        },
        removeId: "",
        removeVisitor: false,
        viewState: LoadState.Loading,
        viewTable: false,
        visitorDate: undefined,
        visitorTime: undefined,
        dateOpen: false,
        timeOpen: false,
    });

    const setState = (newState: Partial<IState>, callback?: () => void) => {
        setLocalState({ ...state, ...newState });
        if (callback) {
            callback();
        }
    };

    useEffect(() => {
        const saveDate: string[] = API.getCustomFieldsFromLocal("saveDate");
        if (saveDate.length === 0 || moment(saveDate[0]).add(8, "h").isBefore(moment())) {
            API.saveCustomFieldLocally();
        }
        loadListOfGuests();
    }, []);

    const loadListOfGuests = () => {
        API.getGuests(true, "", (response) => {
            if (response.state === "success") {
                setState({
                    guests: (response.data as { list: ITreoServerGuest[] }).list,
                    viewTable: true,
                    viewState: LoadState.Ready,
                });
            }
        });
    };

    const signoutVisitor = (leaveDateTime: Date) => {
        setState({ viewState: LoadState.Loading });
        API.signoutVisitor(state.removeId, leaveDateTime, (response) => {
            if (response.state === "success") {
                setState({
                    guests: (response.data as { list: ITreoServerGuest[] }).list,
                    removeId: "",
                    removeVisitor: false,
                    viewState: LoadState.Ready,
                });
            }
        });
    };

    const renderOneGuest = (value: ITreoServerGuest) => {
        const isOld = moment(value.arrivalDate).add(1, "d").isBefore(moment());
        const guestHasError = value.signOutError.trim().length > 0;
        return (
            <tr key={value.id} style={guestHasError ? { color: "#ff0000" } : {}}>
                <td>{value.name}</td>
                <td>{value.company.name}</td>
                <td>{value.host.name}</td>
                <td>{value.location}</td>
                <td className={isOld ? "isOld" : "notOld"}>{moment(value.arrivalDate).format("LLLL")}</td>
                <td>
                    <a
                        onClick={() => {
                            setState(
                                {
                                    printData: {
                                        company: value.company.name,
                                        host: value.host.name,
                                        name: value.name,
                                        timeIn: moment(value.arrivalDate).format("YYYY-MM-DD"),
                                        title: value.salutation,
                                    },
                                },
                                () => API.openLabelPrint(state.printData)
                            );
                        }}
                    >
                        <MaterialIcon className="size24 md-dark">print</MaterialIcon>
                    </a>
                </td>
                <td>
                    <a className="iconButton" onClick={() => setState({ removeId: value.id, removeVisitor: true })}>
                        <MaterialIcon className="size24 md-dark">work_off</MaterialIcon>
                    </a>
                </td>
            </tr>
        );
    };

    if (state.viewState === LoadState.Loading) {
        return <LoadingSpinner />;
    }

    return (
        <Container fluid={false}>
            <NavBar />
            <h3 className="my-3">Aktuelle Besucher</h3>
            {state.viewTable && (
                <ListBox
                    columnHeaders={[
                        "Name",
                        "Firma",
                        "Gastgeber",
                        "Standort",
                        "Ankunft",
                        "Ausweis drucken",
                        "Besucher abmelden",
                    ]}
                    renderOneLine={renderOneGuest as (value: ITreoServerGuest) => JSX.Element}
                    listData={state.guests}
                />
            )}
            <Modal isOpen={state.removeVisitor}>
                <ModalHeader>Besucher abmelden?</ModalHeader>
                <ModalBody>
                    <div>Wollen Sie den Besucher wirklich abmelden?</div>
                    <div style={{ display: "flex", flexDirection: "column", rowGap: "12px" }}>
                        <MobileDatePicker
                            label={"Date"}
                            className="datePickerContainer"
                            inputFormat="DD/MM/yyyy"
                            renderInput={(props) => <TextField {...props} fullWidth variant={"filled"} />}
                            value={state.visitorDate !== undefined ? state.visitorDate : new Date()}
                            onChange={(date) => {
                                if (date) {
                                    setState({
                                        visitorDate: moment(date).toDate(),
                                    });
                                }
                            }}
                            open={state.dateOpen}
                            onOpen={() => setState({ dateOpen: true })}
                            onClose={() => setState({ dateOpen: false })}
                            InputProps={{
                                className: "datePickerInput",
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                setState({
                                                    dateOpen: true,
                                                })
                                            }
                                        >
                                            <CalendarMonth />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <MobileTimePicker
                            label={"Time"}
                            className="datePickerContainer"
                            renderInput={(props) => <TextField {...props} fullWidth variant={"filled"} />}
                            value={state.visitorTime !== undefined ? state.visitorTime : new Date()}
                            onChange={(time) => {
                                if (time) {
                                    setState({
                                        visitorTime: moment(time).toDate(),
                                    });
                                }
                            }}
                            open={state.timeOpen}
                            onOpen={() => setState({ timeOpen: true })}
                            onClose={() => setState({ timeOpen: false })}
                            InputProps={{
                                className: "datePickerInput",
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                setState({
                                                    timeOpen: true,
                                                })
                                            }
                                        >
                                            <AccessTime />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-danger"
                        onClick={() => {
                            let leaveDate: Date;
                            let leaveTime: Date;
                            state.visitorDate === undefined
                                ? (leaveDate = new Date())
                                : (leaveDate = state.visitorDate);
                            state.visitorTime === undefined
                                ? (leaveTime = new Date())
                                : (leaveTime = state.visitorTime);
                            const leaveDateTime = new Date(
                                leaveDate.getFullYear(),
                                leaveDate.getMonth(),
                                leaveDate.getDate(),
                                leaveTime.getHours(),
                                leaveTime.getMinutes()
                            );
                            signoutVisitor(leaveDateTime);
                        }}
                        style={{ width: "calc(50% - 0.5rem)" }}
                    >
                        Ja
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            setState({
                                removeId: "",
                                removeVisitor: false,
                                visitorDate: undefined,
                                visitorTime: undefined,
                            });
                        }}
                        style={{ width: "calc(50% - 0.5rem)" }}
                    >
                        Nein
                    </button>
                </ModalFooter>
            </Modal>
        </Container>
    );
};

export default ListOfGuest;
