import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Input, Row } from "reactstrap";
import API from "../api";
import { getPersonSalutations, IAddVisitorProps, IClient, ICompanyData, ICreateClient, LoadState } from "../constants";
import "./AddVisitor.css";
import LoadingSpinner from "./LoadingSpinner";
import { ListBox, SelectInput } from "./UtilComponents";

interface IState {
    client: ICreateClient;
    companyData: ICompanyData;
    email: string;
    errorView: boolean;
    firstName: string;
    guests: IClient[];
    lastName: string;
    missingFields: string[];
    tableViews: boolean;
    telefon: string;
    title: string;
    titleList: string[];
    viewState: LoadState;
}

const AddVisitor = (props: IAddVisitorProps) => {
    const [state, setLocalState] = useState<IState>({
        client: {
            CompanyId: 0,
            DefaultAddress: {
                Country: "",
                Email: "",
            },
            FirstName: "",
            LastName: "",
            PhoneLandline: "",
            Salutation: "",
        },
        companyData: {
            CompanyTypes: [
                {
                    Name: "",
                },
            ],
            DefaultAddress: {
                City: "",
                Country: "",
                HouseNumber: "",
                Street: "",
                ZipCode: "",
            },
            Id: 0,
            IndustrialSector: "",
            Name: "0",
        },
        email: "",
        errorView: false,
        firstName: "",
        guests: [],
        lastName: "",
        missingFields: [],
        tableViews: false,
        telefon: "",
        title: "",
        titleList: getPersonSalutations(),
        viewState: LoadState.Loading,
    });

    const setState = (newState: Partial<IState>, callback?: () => void) => {
        setLocalState({ ...state, ...newState });
        if (callback) {
            callback();
        }
    };

    useEffect(() => {
        API.getCustomerForCompanyId(props.takeDataFromAnotherChild.Id, (response) => {
            if (response) {
                setState({
                    companyData: props.takeDataFromAnotherChild,
                    guests: response.data as IClient[],
                    tableViews: true,
                    viewState: LoadState.Ready,
                });
            }
        });
    }, []);

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const missingArrayTemp = state.missingFields;
        // tslint:disable-next-line: no-any
        const newState: { [key in keyof IState]: any } = {
            ...state,
        };
        newState[e.target.id as keyof IState] = e.target.value;
        newState.missingFields = missingArrayTemp.filter((entry) => entry !== e.target.id);
        setState(newState);
    };

    const onClickAddVisitor = () => {
        const requiredFields = ["title", "firstName", "lastName"];
        console.log("STATE", state);
        const missingFieldsTemp: string[] = [];
        requiredFields.map((field) => {
            if (state[field as keyof IState] === "" || state[field as keyof IState] === 0) {
                missingFieldsTemp.push(field);
            }
        });
        if (missingFieldsTemp.length > 0) {
            setState({ errorView: true, missingFields: missingFieldsTemp });
        } else {
            const client: ICreateClient = {
                CompanyId: state.companyData.Id,
                DefaultAddress: {
                    Country:
                        state.companyData.DefaultAddress !== null && state.companyData.DefaultAddress.Country !== null
                            ? state.companyData.DefaultAddress.Country
                            : "",
                    Email: state.email,
                },
                FirstName: state.firstName,
                LastName: state.lastName,
                PhoneLandline: state.telefon,
                Salutation: state.title,
            };
            setState({
                tableViews: false,
                viewState: LoadState.Loading,
            });
            API.createContactPerson(client, (response) => {
                if (response.state === "success") {
                    API.getCustomerForCompanyId(state.companyData.Id, (response1) => {
                        if (response1) {
                            setState({
                                email: "",
                                errorView: false,
                                firstName: "",
                                guests: response1.data as IClient[],
                                lastName: "",
                                tableViews: true,
                                telefon: "",
                                title: "",
                                viewState: LoadState.Ready,
                            });
                        }
                    });
                }
            });
        }
    };

    const renderLoadContactPerson = (value: IClient, index?: number) => {
        return (
            <tr key={index}>
                <td>{value.Salutation}</td>
                <td>
                    {value.FirstName} {value.LastName}
                </td>
            </tr>
        );
    };

    const isMissing = (field: string) => {
        return state.missingFields.indexOf(field) !== -1;
    };

    const errorMsg = (
        <legend className="my-3" style={{ color: "red" }}>
            Bitte füllen Sie die roten Felder aus
        </legend>
    );

    if (state.viewState === LoadState.Loading) {
        return <LoadingSpinner />;
    }

    return (
        <Container fluid={false}>
            <h3 className="my-3">{state.companyData.Name}</h3>
            {state.tableViews && (
                <ListBox
                    columnHeaders={["Anrede", "Name"]}
                    renderOneLine={renderLoadContactPerson}
                    listData={state.guests}
                />
            )}
            <br />
            <Row>
                <Col>
                    <legend>Neue Besucher hinzufügen!</legend>
                    <Form method="post" role="form">
                        <Row>
                            <Col md={2}>
                                <SelectInput
                                    id="title"
                                    className={isMissing("title") ? "error" : "form-control"}
                                    selectArrFromParents={state.titleList}
                                    onChangeOption={inputHandler}
                                />
                            </Col>
                            <Col md={5}>
                                <Input
                                    id="firstName"
                                    className={isMissing("firstName") ? "error" : "form-control"}
                                    name="vorname"
                                    placeholder="Vorname"
                                    type="text"
                                    required={true}
                                    onChange={inputHandler}
                                />
                            </Col>
                            <Col md={5}>
                                <Input
                                    id="lastName"
                                    className={isMissing("lastName") ? "error" : "form-control"}
                                    name="nachname"
                                    placeholder="Nachname"
                                    type="text"
                                    required={true}
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Input
                                    id="email"
                                    className="form-control"
                                    name="email"
                                    placeholder="Email"
                                    type="email"
                                    required={true}
                                    onChange={inputHandler}
                                />
                            </Col>
                            <Col md={6}>
                                <Input
                                    id="telefon"
                                    className="form-control"
                                    name="telefon"
                                    placeholder="Telefon"
                                    type="tel"
                                    required={true}
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Row>
                        {state.errorView && errorMsg}
                        <br />
                        <Button size="lg" color="primary" block={true} type="button" onClick={onClickAddVisitor}>
                            Anlegen
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default AddVisitor;
