let serverUrl;

let serverApiUrl;

let appIDSecret;

let appID;

if (process.env.NODE_ENV === "development") {
    // Testing
    appIDSecret = "601571-hq-sync:ImC15SVRi0jUxT9lEdhlC6OEpExf79gc";
    appID = "601571-hq-sync";
    serverUrl = "http://localhost:3042/";
    serverApiUrl = "http://localhost:3042/api/";
} else {
    // staging
    appIDSecret = "601571-hq-sync:ImC15SVRi0jUxT9lEdhlC6OEpExf79gc";
    appID = "601571-hq-sync";
    serverUrl = "https://treo.testingserver3.qubidu.com/";
    serverApiUrl = "https://treo.testingserver3.qubidu.com/api/";

    // Live
    // appIDSecret = "592480-hq-sync:ImC15SVRi0jUxT9lEdhlC6OEpExf79gc";
    // appID = "592480-hq-sync";
    // serverUrl = "https://qlive.treo.de/";
    // serverApiUrl = "https://qlive.treo.de/api/";
}

export const APP_ID_AND_APP_SECRET = appIDSecret;
export const APP_ID = appID;

export const TOKEN_STORAGE_KEY = "__treo_hq_access_token";

export const CUSTOM_FIELDS_STORAGE_KEY = "__treo_hq_custom_fields";

export const HQ_URL = "https://api.hqlabs.de/v1/";

export const TOKEN_HQ_URL = "https://api.hqlabs.de/Token";

export const TREO_SERVER_URL = serverUrl;

export const TREO_SERVER_API_URL = serverApiUrl;

export enum LoadState {
    Loading,
    Saving,
    Ready,
}

export interface IAccessData {
    accessToken: string;
    refreshToken: string;
    userId: number;
    userName: string;
}

export interface IAddGuestObj {
    firstName: string;
    lastName: string;
    entryId: number;
    company: IAddGuestObjCompany;
    host: IAddGuestObjHost;
    location: string;
    salutation: string;
    signature: string;
}

export interface IAddGuestObjCompany {
    id: number;
    name: string;
}

export interface IAddGuestObjHost {
    id: number;
    firstName: string;
    lastName: string;
}

export interface IOfferViewProps {
    match: {
        params: {
            id: number;
        };
    };
}

export interface IAddNewCompanyProps {
    passDataToNewView: (data: ICompanyData) => void;
}

export interface IAddVisitorProps {
    takeDataFromAnotherChild: ICompanyData;
}

export interface IListBoxProps<T> {
    columnHeaders: string[];
    renderOneLine: (value: T, index: number) => JSX.Element;
    listData: T[];
    headerStyle?: string;
}

export interface IEmptyProps {
    children?: React.ReactNode;
}

export interface IFilterSelectProps {
    label: string;
    value: string[];
    updateFunc: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export interface IFilterTextProps {
    label: string;
    value: string;
    updateFunc: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface ISelectInputProps {
    onChangeOption: (e: React.ChangeEvent<HTMLInputElement>) => void;
    selectArrFromParents: string[];
    children?: React.ReactNode;
    className?: string;
    id?: string;
    inline?: boolean;
    defaultValue?: string;
}

export interface IOfferDetailsProps {
    backButtonDisplay: boolean;
    backButtonFunc: () => void;
    quotation: IQuotations;
    deletePosition: () => void;
}

export interface IOfferSearchProps {
    ifSearching: (bool: boolean) => void;
}

export interface ISearchBtnProps {
    searchValue: (str: string, isSearch: boolean) => void;
    children?: React.ReactNode;
}

export interface ISearchCompaniesProps {
    passDataToAddnewCompany: (data: ICompanyData) => void;
    className: string;
}

export interface IAccessData {
    accessToken: string;
    refreshToken: string;
    userId: number;
    userName: string;
}

export interface IEntry {
    header: string;
    field: string;
}

export interface ICustomFieldsObject {
    Name: string;
    Value: string;
}

export interface IArticleProps {
    articleHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
    cardLayout: number[];
    checkedArticle: boolean;
    data: IEntry[];
    index: number;
}

export interface ICallbackResponse {
    state?: string;
    status?: string;
    data?:
        | ICallbackResponseList
        | IAccessData
        | ICompanyData
        | ICompanyData[]
        | IHQArticle
        | IHQArticle[]
        | IClient
        | IClient[]
        | IQuotations
        | IQuotations[]
        | IPositions
        | IPositions[]
        | ITreoServerGuest
        | ITreoServerGuest[];
    message?: string;
    code?: number;
}

export interface ICallbackResponseList {
    list: ITreoServerGuest[];
}

export interface IFluidFilterObject {
    Name: string;
    Id: number;
}

export interface IHQArticle {
    Category: ICategory;
    CustomFields: IHQCustomField[];
    Description: string;
    Id: number;
    Name: string;
    Number: string;
    SalesPrices: ISalesPrices[];
    Stocks: IArticleStocks[];
}

export interface IArticleStocks {
    CurrentStock: number;
}

export interface ICategory {
    Name: string;
    ParentId: number;
    Id: number;
}

export interface ISalesPrices {
    Currency: string;
    Price: number;
    TaxRate: number;
}

export interface IHQCustomField {
    Id: number;
    Name: string;
    Value: string;
    Type: string;
}

export interface IPrintData {
    company: string;
    name: string;
    timeIn: string;
    title: string;
    host: string;
}

export interface ICompanyData {
    CompanyTypes: [
        {
            Name: string;
        }
    ];
    DefaultAddress: {
        City: string;
        Country: string;
        HouseNumber: string;
        Street: string;
        ZipCode: string;
    };
    Id: number;
    IndustrialSector: string;
    Name: string;
}

export interface ICompany {
    CompanyTypes: [
        {
            Name: string;
        }
    ];
    DefaultAddress: {
        City: string;
        Country: string;
        HouseNumber: string;
        Street: string;
        ZipCode: string;
    };
    IndustrialSector: string;
    Name: string;
}

export interface IClient {
    CompanyId: number;
    DefaultAddress: {
        Country: string;
        Email: string;
    };
    FirstName: string;
    Id: number;
    LastName: string;
    PhoneLandline: string;
    Salutation: string;
}

export interface ITreoServerGuest {
    id: string;
    entryId: number;
    salutation: string;
    location: string;
    name: string;
    firstName: string;
    lastName: string;
    company: { id: number; name: string };
    host: ITreoServerHost;
    inBuilding: boolean;
    arrivalDate: string;
    departureDate?: string;
    signature?: string;
    signOutError: string;
}

export interface ITreoServerHost {
    id: number;
    name: string;
    firstName: string;
    lastName: string;
}

export interface ICreateClient {
    CompanyId: number;
    DefaultAddress: {
        Country: string;
        Email: string;
    };
    FirstName: string;
    LastName: string;
    PhoneLandline: string;
    Salutation: string;
}

export interface IPositions {
    Amount: number;
    Article: IHQArticle;
    Id: number;
    Price: number;
    Tax: number;
    Text: string;
    TotalPrice: number;
    Unit: string;
}

export interface IQuotations {
    Company: { Name: string };
    CompanyId: number;
    Id: number;
    Lead: { Number: string };
    Number: string;
    Positions: IPositions[];
    QuotationDate: string;
    Status: string;
}

export function getCompanyTypes(): string[] {
    return [
        "- Unternehmenstype",
        "GbR",
        "KG",
        "AG & Co. KG",
        "GmbH & Co. KG",
        "Limited & Co. KG",
        "Stiftung & Co. KG",
        "Stiftung GmbH & Co. KG",
        "UG (haftungsbeschränkt) & Co. KG",
        "OHG",
        "GmbH & Co. OHG",
        "AG & Co. OHG",
        "Partenreederei",
        "PartG",
        "PartG mbB",
        "Stille Gesellschaft",
        "AG",
        "gAG",
        "GmbH",
        "gGmbH",
        "InvAG",
        "KGaA",
        "AG & Co. KGaA",
        "SE & Co. KGaA",
        "GmbH & Co. KGaA",
        "Stiftung & Co. KGaA",
        "REIT-AG",
        "UG (haftungsbeschränkt)",
        "AöR",
        "eG",
        "Eigenbetrieb",
        "Einzelunternehmen",
        "e.V.",
        "KöR",
        "Regiebetrieb",
        "Stiftung",
        "VVaG",
        "EWIV",
        "geplant: SUP",
        "SE",
        "SCE",
    ];
}

export function getPersonSalutations(): string[] {
    return [
        "- Anrede",
        "Herr",
        "Frau",
        "Herr Dr.",
        "Frau Dr.",
        "Herr Prof.",
        "Frau Prof.",
        "Herr Prof. Dr.",
        "Frau Prof. Dr.",
        "Frau Dipl. -Ing.",
        "Frau Dl",
        "Frau Dipl. -Ing. (FH)",
        "Frau Dr. Dipl. Ing.",
        "Frau Dl (FH)",
        "Herr Dipl. -Ing.",
        "Herr Dl",
        "Herr Dipl. -Ing. (FH)",
        "Herr Dr. Dipl. Ing.",
        "Herr Dl (FH)",
        "Frau Ing.",
        "Herr Ing.",
        "Herr MMag",
        "Frau MMag",
        "Herr Mag",
        "Frau Mag",
        "Frau DDr.",
        "Herr DDr.",
    ];
}

export function getCompanySectors(): string[] {
    return [
        "- Branch",
        "Handel",
        "Elektroindustrie",
        "Automobilindustrie",
        "IT-Branche",
        "Energiewirtschaft",
        "Immobilienwirtschaft",
        "Metallbranche",
        "Verkehrsbranche",
        "Chemischen Industrie",
        "Dienstleistungsbranche",
        "Gesundheitswesen",
        "Nahrungsmittelindustrie",
        "Baugewerbe",
        "Versicherungen",
        "Kunststoffindustrie",
        "Stahlindustrie",
        "Textilbranche",
        "Glas & Keramikindustrie",
        "Forschung & Entwicklung",
        "Bildungswesen",
        "Pharmaindustrie",
        "Medienbranche",
        "Banken",
        "Unternehmensberatung",
        "Transportwesen",
        "Bergbaubranche",
        "Druckindustrie",
        "Öffentlicher Dienst",
        "Telekommunikationsbranche",
        "Papierindustrie",
        "Finanzdienstleistung",
        "Sozialer Dienst",
        "Luftfahrt",
        "Werbebranche",
        "Rechtsberatung & Wirtschaftsprüfung",
        "Freizeitbranche",
        "Tourismusbranche",
        "Internetbranche",
        "Gastgewerbe",
        "Möbelindustrie",
        "Holzindustrie",
    ];
}

export function getCountries(): string[] {
    return [
        "- Land",
        "AD – Andorra",
        "AE – Vereinigte Arabische Emirate",
        "AF – Afghanistan",
        "AG – Antigua und Barbuda",
        "AI – Anguilla",
        "AL – Albanien",
        "AM – Armenien",
        "AO – Angola",
        "AQ – Antarktika",
        "AR – Argentinien",
        "AS – Amerikanisch-Samoa",
        "AT – Österreich",
        "AU – Australien",
        "AW – Aruba",
        "AZ – Aserbaidschan",
        "BA – Bosnien und Herzegowina",
        "BB – Barbados",
        "BD – Bangladesch",
        "BE – Belgien",
        "BF – Burkina Faso",
        "BG – Bulgarien",
        "BH – Bahrain",
        "BI – Burundi",
        "BJ – Benin",
        "BL – Sankt Bartholomäus",
        "BM – Bermuda",
        "BN – Brunei",
        "BO – Bolivien",
        "BR – Brasilien",
        "BS – Bahamas",
        "BT – Bhutan",
        "BV – Bouvetinsel",
        "BW – Botsuana",
        "BY – Belarus",
        "BZ – Belize",
        "CA – Kanada",
        "CC – Kokosinseln (Keelinginseln)",
        "CD – Demokratische Republik Kongo",
        "CF – Zentralafrikanische Republik",
        "CG – Kongo",
        "CH – Schweiz",
        "CI – Cote d'Ivoire",
        "CK – Cookinseln",
        "CL – Chile",
        "CM – Kamerun",
        "CN – China",
        "CO – Kolumbien",
        "CR – Costa Rica",
        "CU – Kuba",
        "CV – Kap Verde",
        "CW – Curacao",
        "CX – Weihnachtsinsel",
        "CY – Zypern",
        "CZ – Tschechische Republik",
        "DE – Deutschland",
        "DJ – Dschibuti",
        "DK – Dänemark",
        "DM – Dominica",
        "DO – Dominikanische Republik",
        "DZ – Algerien",
        "EC – Ecuador",
        "EE – Estland",
        "EG – Ägypten",
        "EH – Westsahara",
        "ER – Eritrea",
        "ES – Spanien",
        "ET – Äthiopien",
        "FI – Finnland<",
        "FJ – Fidschi",
        "FK – Falklandinseln (Malwinen)",
        "FM – Mikronesien, Föderierte Staaten von",
        "FO – Färöer",
        "FR – Frankreich",
        "FX – Französisches Mutterland",
        "GA – Gabun",
        "GB – Vereinigtes Königreich",
        "GD – Grenada",
        "GE – Georgien",
        "GF – Französisch-Guyana",
        "GG – Guernsey",
        "GH – Ghana",
        "GI – Gibraltar",
        "GL – Grönland",
        "GM - Gambia",
        "GN – Guinea",
        "GP – Guadeloupe",
        "GQ – Äquatorialguinea",
        "GR – Griechenland",
        "GS – Südgeorgien und die südlichen Sandwichinseln",
        "GT – Guatemala",
        "GU – Guam",
        "GW – Guinea-Bissau",
        "GY – Guyana",
        "HK – Hongkong",
        "HM – Heard- und McDonald-Inseln",
        "HN – Honduras",
        "HR – Kroatien",
        "HT – Haiti",
        "HU – Ungarn",
        "ID – Indonesien",
        "IE – Irland",
        "IL – Israel",
        "IM – Insel Man",
        "IN – Indien",
        "IO – Britisches Territorium im Indischen Ozean",
        "IQ – Irak",
        "IR – Iran",
        "IS – Island",
        "IT – Italien",
        "JE – Jersey",
        "JM – Jamaika",
        "JO – Jordanien",
        "JP – Japan",
        "KE – Kenia",
        "KG – Kirgisistan",
        "KH – Kambodscha",
        "KI – Kiribati",
        "KM – Komoren",
        "KN – St. Kitts und Nevis",
        "KP – Nordkorea",
        "KR – Südkorea",
        "KW – Kuwait",
        "KY – Kaimaninseln",
        "KZ – Kasachstan",
        "LA – Laos",
        "LB – Libanon",
        "LC – St. Lucia",
        "LI – Liechtenstein",
        "LK – Sri Lankav",
        "LR – Liberia",
        "LS – Lesotho",
        "LT – Litauen",
        "LU – Luxemburg",
        "LV – Lettland",
        "LY – Libyen",
        "MA – Marokko",
        "MC – Monaco",
        "MD – Moldau",
        "ME – Montenegro",
        "MF – St. Martin",
        "MG – Madagaskar",
        "MH – Marshallinseln",
        "MK – Mazedonien",
        "ML – Mali",
        "MM – Burma (Myanmar)",
        "MN – Mongolei",
        "MO – Macau",
        "MP – Nördliche Marianen",
        "MQ – Martinique",
        "MR – Mauretanien",
        "MS – Montserrat",
        "MT – Malta",
        "MU – Mauritius",
        "MV – Malediven",
        "MW – Malawi",
        "MX – Mexiko",
        "MY – Malaysia",
        "MZ – Mosambik",
        "NA – Namibia",
        "NC – Neukaledonien",
        "NE – Niger",
        "NF – Norfolkinsel",
        "NG – Nigeria",
        "NI – Nicaragua",
        "NL – Niederlande",
        "NO – Norwegen",
        "NP – Nepal",
        "NR – Nauru",
        "NU – Niue",
        "NZ – Neuseeland",
        "OM – Oman",
        "PA – Panama",
        "PE – Peru",
        "PF – Französisch-Polynesien",
        "PG – Papua-Neuguinea",
        "PH – Philippinen",
        "PK – Pakistan",
        "PL – Polen",
        "PM – St. Pierre und Miquelon",
        "PN – Pitcairn",
        "PR – Puerto Rico",
        "PS – Gazastreifen",
        "PS – Westjordanland",
        "PT – Portugal",
        "PW – Palau",
        "PY – Paraguay",
        "QA – Katar",
        "RE – Reunion",
        "RO – Rumänien",
        "RS – Serbien",
        "RU – Russland",
        "RW – Ruanda",
        "SA – Saudi-Arabien",
        "SB – Salomonen",
        "SC – Seychellen",
        "SD – Sudan",
        "SE – Schweden",
        "SG – Singapur",
        "SH – St. Helena, Ascension und Tristan da Cunha",
        "SI – Slowenien",
        "SJ – Spitzbergen",
        "SK – Slowakei",
        "SL – Sierra Leone",
        "SM – San Marino",
        "SN – Senegal",
        "SO – Somalia",
        "SR – Surinam",
        "SS – Süd-Sudan",
        "ST – São Tomé und Príncipe",
        "SV – El Salvador",
        "SX – St. Maarten",
        "SY – Syrien",
        "SZ – Swasiland",
        "TC – Turks- und Caicosinseln",
        "TD – Tschad",
        "TF – Französische Süd- und Antarktisgebiete",
        "TG – Togo",
        "TH – Thailand",
        "TJ – Tadschikistan",
        "TK – Tokelau",
        "TL – Timor-Leste",
        "TM – Turkmenistan",
        "TN – Tunesien",
        "TO – Tonga",
        "TR – Türkei",
        "TT – Trinidad und Tobago",
        "TV – Tuvalu",
        "TW – Taiwan",
        "TZ – Tansania",
        "UA – Ukraine",
        "UG – Uganda",
        "UM – Kleinere amerikanische Überseeinseln",
        "US – USA",
        "UY – Uruguay",
        "UZ – Usbekistan",
        "VA – Heiliger Stuhl (Vatikanstadt)",
        "VC – St. Vincent und die Grenadinen",
        "VE – Venezuela",
        "VG – Britische Jungferninseln",
        "VI – Amerikanische Jungferninseln",
        "VN – Vietnam",
        "VU – Vanuatu",
        "WF – Wallis und Futuna",
        "WS – Samoa",
        "XK – Kosovo",
        "YE – Jemen",
        "YT – Mayotte",
        "ZA – Südafrika",
        "ZM – Sambia",
        "ZW – Simbabwe",
    ];
}
